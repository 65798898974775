import React, {useState, useEffect} from 'react';
import {Link, Redirect, Route, useHistory} from "react-router-dom";
import Swal from 'sweetalert2';
import api from '../../../services/Api'; 
import InputMask from 'react-input-mask'; 

// import { Container } from './styles';

export default function SignIn1() {

  const [cpf, setCPF] = useState();
  const [senha, setSenha] = useState();
  const [token, setToken] = useState();
  const history = useHistory(); 

  useEffect(() => {
    //something
  }, []);

 

  async function sendToken(token){
    await api.post('api', {}, { 
      headers: { 
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}` 
      } 
    }
    ).then(response => {
      const arr = {
        "user_id": response.data.user_id,
        "username": response.data.username,
        "email": response.data.email,
        "nome": response.data.nome,
        "sobrenome": response.data.sobrenome,
        "cpf": response.data.cpf,
        "fone_dd": response.data.fone_dd,
        "fone_numero": response.data.fone_numero,
        "logradouro": response.data.logradouro,
        "numero": response.data.numero,
        "complemento": response.data.complemento,
        "bairro": response.data.bairro,
        "cidade": response.data.cidade,
        "estado": response.data.estado,
        "cep": response.data.cep,
        "token": response.data.token.credentials,
      };

      localStorage.setItem('credentials', JSON.stringify(arr));
 
      history.push('/');   
    }).catch(err => {
      if(err.response.data.code == 401){
        Swal.fire(
          'Oops',
          'Esse token não é válido no momento. Tente novamente',
          'warning'
        )
      }else{
        Swal.fire(
          'Oops',
          'Houve algum problema na conversa entre seu computador e nosso servidor. Tente novamente mais tarde.',
          'warning'
        )
      }
      console.log(err.response);
    })
  }

  async function submit(event){
    event.preventDefault();

    if(cpf.includes("_")){
      Swal.fire("CPF", 'O cpf não foi digitado corretamente', 'warning');
      return;
    }

    if(!cpf || cpf.length != 14){
      Swal.fire('CPF', 'CPF não foi digitado corretamente', 'warning');
      return;
    }else if(!senha){
      Swal.fire('Senha', 'Senha não foi informada', 'warning');
      return;
    }

    let cpf_limpo = cpf.split(".").join("").replace("-", "");

    

    await api.post('login_check', {username: cpf_limpo, password: senha})
    .then(response => {
      console.log(response.data.data);
      sendToken(response.data.token);
    })
    .catch(err => { 
      if(err.response.data.code == 401){
        Swal.fire(
          'Dados incorretos', 
          'Sua senha ou seu CPF estão incorretos',
          'error'
        );
      } 
    });    
  }


  return (
    <>
    <div className="sign-in-from">
        <h1 className="mb-0">Mil Contas</h1>
        <p>Digite CPF e sua senha para entrar no sistema.</p>
        <form className="mt-4">
            <div className="form-group">
                <label htmlFor="exampleInputEmail1">CPF</label>
                <InputMask mask="999.999.999-99" type="text" className="form-control mb-0" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Digite o CPF" onChange={(e) => setCPF(e.target.value)} id="cpf" required />
                {/* <input onChange={(e) => setCPF(e.target.value)} type="text" maxLength={11} className="form-control mb-0"   /> */}
            </div>
            <div className="form-group">
                <label htmlFor="exampleInputPassword1">Senha</label>
                <Link to="/" className="float-right">Esqueceu a senha?</Link>
                <input onChange={(e) => setSenha(e.target.value)} defaultValue={senha} type="password" className="form-control mb-0" id="exampleInputPassword1" placeholder="Digite sua senha" />
            </div>
            <div className="d-inline-block w-100">
                <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                    <input type="checkbox" className="custom-control-input" id="customCheck1" />
                        <label className="custom-control-label" htmlFor="customCheck1">Lembrar-me</label>
                </div>
                <button type="submit" onClick={(event) => submit(event)} className="btn btn-primary float-right">Entrar</button>
            </div>
            <div className="sign-info">
                <span className="dark-color d-inline-block line-height-2">Não tem uma conta? <Link to="/auth/sign-up1">Criar conta</Link></span>
                <ul className="iq-social-media">
                    <li><Link to="/"><i className="ri-facebook-box-line" /></Link></li>
                    <li><Link to="/"><i className="ri-twitter-line" /></Link></li>
                    <li><Link to="/"><i className="ri-instagram-line" /></Link></li>
                </ul>
            </div>
        </form>
    </div>
</>
  );
}





