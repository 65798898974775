import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, Form, Label } from "reactstrap";
import { index } from "../../../config/pluginsInit";
import { useForm } from 'react-hook-form';
import { Modal } from "react-bootstrap";
import api from '../../../services/Api';

import { Pagination } from 'react-bootstrap';

import mixin from '../../../Utils/mixin';
import Swal from 'sweetalert2';

export default function Especies() {
    
    const {register, handleSubmit} = useForm();
    
    const [getId, setId] = useState([]);
    const [Lista, setListagem] = useState([]); 
    const [ListaEdit, setListagemEdit] = useState([]); 

    const [pagination, setPagination] = useState([]);

    const [ModalOpen, setModalOpen] = React.useState(false); // ABRE E FECHA O MODAL
    const [ModalOpenEdit, setModalOpenEdit] = React.useState(false); // ABRE E FECHA O MODAL

    const [ModalToggle, setModalToggle] = React.useState('adicionar'); // ABRE E FECHA O MODAL
    const [ModalToggleEdit, setModalToggleEdit] = React.useState('editar'); // ABRE E FECHA O MODAL

    // lista os dados na tabela
    useEffect(() => {
        async function loadLista(){
          const response = await api.get('/species/');
          setListagem(response.data.data);
          console.log(response.data.data)
        }
        loadLista();

        index(); // fecha o loading

    }, []);

    // adicionar 
    async function handleModalAdd(acao){ setModalOpen(acao) }  // modal

    const onSubmit = async (data) => {
        
        const user_id = 2;
    
        const response = await api.post(`/species/`, {
            license: data.license,
            name: data.name,
            initials: data.initials,
            active: data.active
        });
        
        mixin('add');
    
        async function loadLista(){
          const response = await api.get('/species/');
          setListagem(response.data.data);
        }
    
        loadLista();
    
        setModalOpen(false);
        
    }  
    
    // editar  
    async function handleModalEditar(id, acao){ 
        
        const response = await api.get(`/species/${id}`);
        setListagemEdit(response.data.data); 
        setId(id);
        setModalOpenEdit(acao);
    
    } 

    const onSubmitEdit = async (data) => {    

        const user_id = 2;
    
        const response = await api.put(`/species/${getId}`, {
            license: data.license,
            name: data.name,
            initials: data.initials,
            active: data.active
        });
        
        mixin('edit');
    
        async function loadLista(){
          const response = await api.get('/species/');
          setListagem(response.data.data);
        }
    
        loadLista();
    
        setModalOpenEdit(false);

    }      
    
    // deletar
    async function handleDeletePeca(id){

        const user_id = 2;
    
        Swal.fire({
          title: 'Tem certeza?',
          text: "Essa ação não pode ser desfeita!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Deletar',
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
    
            async function deleteAcao(){
              
                const response = await api.delete(`/species/${id}`);
    
                async function loadLista(){
                    const response = await api.get('/species/');
                    setListagem(response.data.data);
                }
    
                loadLista();
                
            }
    
            deleteAcao();
          }
        })
      }

    return (
        <>
        <div>
            
            <Modal
                size="lg"
                show={ModalOpen}
                onHide="{() => setModalOpen(false)}"
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Adicionar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                        <Row form>
                            <input type="hidden" value="1" name="license" ref={register} />
                            <Col md={12} className="mb-3">
                                <Label for="name"> Nome </Label>
                                <input 
                                    type="text" 
                                    maxLength="255"
                                    minLength="2"
                                    className="form-control" 
                                    id="name" 
                                    name="name"
                                    ref={register}                                
                                    required />
                            </Col>                            
                            <Col md={12} className="mb-3">
                                <Label for="active"> Situação </Label>
                                <select name="active" ref={register} className="form-control" id="active" required>
                                    <option disable selected value="">Selecione...</option>
                                    <option value="1"> Ativo </option>
                                    <option value="0"> Inativo </option>                                    
                                </select>
                            </Col>                            
                            
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => handleModalAdd(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>

            <Modal
                size="lg"
                show={ModalOpenEdit}
                onHide="{() => handleModalEditar('', false)}"
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmitEdit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Editar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                    {ListaEdit.map(edit => (
                        <Row form key={edit.id}>
                            <input type="hidden" value="1" name="license" ref={register} />
                            <Col md={12} className="mb-3">
                                <Label for="name"> Nome </Label>
                                <input 
                                    type="text" 
                                    maxLength="255"
                                    minLength="2"                                    
                                    className="form-control" 
                                    id="name" 
                                    defaultValue={edit.name}                                    
                                    name="name"
                                    ref={register}                                
                                    required />
                            </Col>
                            <Col md={12} className="mb-3">
                                <Label for="active"> Situação </Label>
                                <select name="active" ref={register} className="form-control" defaultValue={edit.active} id="active" required>
                                    <option disable selected value="">Selecione...</option>
                                    <option value="1"> Ativo </option>
                                    <option value="0"> Inativo </option>                                    
                                </select>
                            </Col>                            
                            
                        </Row>
                    ))}
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => handleModalEditar('', false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>
        </div>        


            <Row>
                <Col sm="12">
                    <Card className="iq-card">
                        <CardHeader className="iq-card-header d-flex justify-content-between">
                            <CardTitle className="iq-header-title">
                                <h4 className="card-title"> Planos </h4>
                            </CardTitle>
                            <span className="float-right" >
                                <Button color="warning" className="btn-filter"  type="submit"><i className="ri-add-circle-line" /> Abrir Filtro</Button>
                                <Button color="primary" onClick={ () => handleModalAdd(true) } type="submit"><i className="ri-add-circle-line" /> Adicionar</Button>
                            </span>
                        </CardHeader>
                        {/* <CardBody>
                        
                                <input 
                                    type="text" 
                                    maxLength="255"
                                    minLength="2"                                    
                                    className="form-control" 
                                    placeholder="Digite para pesquisar..."
                                     />
                            
                        </CardBody> */}
                        <CardBody className="iq-card-body">
                        <Table responsive striped className="table">
                                <thead>
                                    <th scope="col" className="text-center"> ID </th>
                                    <th scope="col"> Nome </th>
                                    <th scope="col" className="text-center"> Informação </th>
                                    <th scope="col" className="text-center"> Ordem ID </th>
                                    <th scope="col" className="text-center"> Tipo </th>
                                    <th scope="col" className="text-center"> Declaração </th>
                                    <th scope="col" className="text-center"> Ativo </th>
                                    <th scope="col" className="text-center"> Editar </th>
                                    <th scope="col" className="text-center"> Deletar </th>
                                </thead>
                                <tbody>
                                
                                <tr >
                                    <td className="Tabela_id"> 1 </td>
                                    <td> nome </td>
                                    <td className="text-center"> informacao </td>
                                    <td className="text-center"> Ordem ID </td>
                                    <td className="text-center"> tipo </td>
                                    <td className="text-center"> declaracao </td>
                                    <td className="text-center"> ativo </td>
                                    <td className="Tabela_apagar">
                                        <Button outline color="danger" onClick={() => handleDeletePeca(1)} className="btn-fintoro"><i className="ri-eraser-line" /></Button>                                            
                                    </td>
                                    <td className="Tabela_editar">
                                        <Button outline color="success" onClick={ () => handleModalEditar(1, true) } className="btn-fintoro"><i className="ri-edit-box-line" /></Button>
                                    </td>
                                </tr>
                               
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            { (pagination == 1) ? "" :
        <Pagination className={"justify-content-end"}>
          <Pagination.Item>{"Anterior"}</Pagination.Item>
          {       
          [...Array(pagination)].map((x, i) =>
            <Pagination.Item onClick={() => ((i+1))} key={i}>{(i+1)}</Pagination.Item>
          )} 
          <Pagination.Item >{"Próximo"}</Pagination.Item>
        </Pagination>
        }  
        </>
    );

}