import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, Form, Label } from "reactstrap";
import { index } from "../../../config/pluginsInit";
import { useForm } from 'react-hook-form';
import { Modal } from "react-bootstrap";
import api from '../../../services/Api';
import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons'

import { Pagination } from 'react-bootstrap';

import mixin from '../../../Utils/mixin';
import Swal from 'sweetalert2';
import Estados from '../../../Utils/Estados';
import validadorCPF from '../../../Utils/Cpf';

import './Styled.css';

export default function Documentos() {

    const baseRoute = "/voucher";

    const [cep, setCEP] = useState();
    const [cpf, setCPF] = useState();
    const [phone, setPhone] = useState();
    const arr = JSON.parse(localStorage.getItem('credentials'));
    const token = arr.token;
  
    
    const {register, handleSubmit} = useForm();
    
    const [id, setId] = useState([]);
    const [system, setSystem] = useState([]);
    const [systemOne, setSystemOne] = useState([]);

    const [pagination, setPagination] = useState([]);

    const [ModalOpen, setModalOpen] = React.useState(false); // ABRE E FECHA O MODAL
    const [ModalOpenEdit, setModalOpenEdit] = React.useState(false); // ABRE E FECHA O MODAL
    const [modalOpenSeen, setModalOpenSeen] = React.useState(false);
    const [ModalEditValoresAfiliado, setModalEditValoresAfiliado] = useState(false);
    const [modalAddValoresAfiliado, setModalAddValoresAfiliado] = useState(false);
    

    const [ModalToggle, setModalToggle] = React.useState('adicionar'); // ABRE E FECHA O MODAL
    const [ModalToggleEdit, setModalToggleEdit] = React.useState('editar'); // ABRE E FECHA O MODAL

    function formatMoney(
      amount,
      decimalCount = 2,
      decimal = ",",
      thousands = "."
    ) {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
  
        const negativeSign = amount < 0 ? "-" : "";
  
        let i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        let j = i.length > 3 ? i.length % 3 : 0;
  
        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
          (decimalCount
            ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
            : "")
        );
      } catch (e) {
        console.log(e);
      }
    }

    // lista os dados na tabela
    useEffect(() => {
        autoload()

        index(); // fecha o loading

    }, []);

    // adicionar 
    async function handleModalAdd(acao){ setModalOpen(acao) }  // modal

    async function autoload(){
      

      // const response = await api.get(baseRoute, {}, { 
      //   headers: { 
      //     'Content-Type': "application/json",
      //     'Authorization': `Bearer ${token}` 
      //   } 
      // }); 

      const response = await api.get(baseRoute); 
      setSystem(response.data.data);
    }

    const onSubmit = async (data) => {
      

        const username = cpf.split(".").join("").replace("-", "");

        const responsecpf = await validadorCPF(username);

        if(responsecpf != true){
          Swal.fire("CPF", 'O CPF é inválido!', 'warning');
          return
        }

    
        const response = await api.post(baseRoute, {
          voucher: data.voucher,
          name: data.name,
          last_name: data.last_name,
          cpf: cpf,
          phone: phone,
          email: data.email,
          agency: data.agency,
          current_account: data.current_account,
          bank: data.bank,
          active:	data.active
        },{ 
          headers: { 
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}` 
          } 
        });

        if(response.data.success == true){
            mixin('add');
            autoload();
            setModalOpen(false);
        }else{
            Swal.fire('Atenção', response.data.message, 'error');
            //mixin('error');
        }
    }  
    
    // editar  
    const [client_mil, setClient_mil] = useState([]);
    async function handleModalEditar(id, acao){ 
      

        const response = await api.get(baseRoute + '/' +  id, {}, { 
          headers: { 
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}` 
          } 
        });

        
        console.log(response.data.data)
        setSystemOne(response.data.data)
        // setCPF(response.data.data[0].cpf)
        setPhone(response.data.data[0].phone);
        
        setId(id);
        setModalOpenEdit(true);
        
        
    
    } 


    const onSubmitEdit = async (data) => {  
          
      const response = await api.put(baseRoute + '/' + id, {
        // voucher: data.voucher,
        name: data.name,
        last_name: data.last_name,
        phone: phone,
        email: data.email,
        agency: data.agency,
        current_account: data.current_account,
        bank: data.bank,
        active:	data.active
      },{ 
        headers: { 
          'Content-Type': "application/json",
          'Authorization': `Bearer ${token}` 
        } 
      });

      if(response.data.success == true){
          mixin('add');
          autoload();
          setModalOpenEdit(false);
      }else{
          Swal.fire('Atenção', response.data.message, 'error');
          //mixin('error');
      }
     

    }
    
    async function deleteAfiliado(id){
      const response = await api.put(baseRoute + "/delete" , {
        id: id
      },{ 
        headers: { 
          'Content-Type': "application/json",
          'Authorization': `Bearer ${token}` 
        } 
      });

      if(response.data.success == true){
        mixin('delete');
        autoload();
      }else{
          Swal.fire('Atenção', response.data.message, 'error');
          //mixin('error');
      }
    }
    
    function deleteAfiliadoAlert(id){
      Swal.fire({
        title: 'Você tem certeza?',
        text: "Essa ação não poderá ser desfeita!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, delete!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          deleteAfiliado(id);
        }
      })
    }

    const toInputUppercase = e => {
      e.target.value = ("" + e.target.value).toUpperCase();
    };

    const [comissoes, setComissoes] = useState([]);
    
    async function handleModalSeen(id){
      setId(id);
      const response = await api.get(baseRoute + '/values/' +  id, {}, { 
        headers: { 
          'Content-Type': "application/json",
          'Authorization': `Bearer ${token}` 
        } 
      });

      console.log(response.data.data);
      setComissoes(response.data.data);

      setModalOpenSeen(true);
    }

    

    async function onSubmitEditValoresAfiliado(data){
      if(valor == "" || porcentagem == "" || desconto == ""){
        Swal.fire('Atenção', 'Há campos vazios!', 'warning');
        return;
      }

      console.log(valor, porcentagem, desconto);

      const newValue = valor.split(".").join("").replace(",", ".").replace("R$ ", "");
      const newPercentage = porcentagem.replace(" %", "").replace(",", ".");
      const newDiscont = desconto.replace(" %", "").replace(",", ".");

      const response = await api.put(baseRoute + "/values/"+ valorId, {
        active: data.active,
        value_money: newValue,
        porcentage:newPercentage,
        discount:newDiscont,
      })

      if(response.data.success == true){
        mixin('edit');
        setModalEditValoresAfiliado(false);
        handleModalSeen(id);
      }else{
          Swal.fire('Atenção', response.data.message, 'error');
          //mixin('error');
      }
    }

    const [valor, setValor] = useState();
    const [porcentagem, setPorcentagem] = useState();
    const [desconto, setDesconto] = useState();

    async function onSubmitAddValoresAfiliado(data){
      if(valor == "" || porcentagem == "" || desconto == ""){
        Swal.fire('Atenção', 'Há campos vazios!', 'warning');
        return;
      }

      const newValue = valor.split(".").join("").replace(",", ".").replace("R$ ", "");
      const newPercentage = porcentagem.replace(" %", "").replace(",", ".");
      const newDiscont = desconto.replace(" %", "").replace(",", ".");

      const response = await api.post(baseRoute + "/values", {
        user_id: id,
        active: data.active,
        value_money: newValue,
        porcentage:newPercentage,
        discount:newDiscont,
      })

      if(response.data.success == true){
        mixin('add');
        setModalAddValoresAfiliado(false);
        handleModalSeen(id);
      }else{
          Swal.fire('Atenção', response.data.message, 'error');
          //mixin('error');
      }
    }

    const [ afiliadoValorUm, setAfiliadoValorUm ] = useState([]);
    const [ valorId, setValorId] = useState();
    async function handleModalEditAfiliadoValor(idValue){
      setValorId(idValue);

      const response = await api.get(baseRoute + '/values/' +  id + '/' + idValue, {}, { 
        headers: { 
          'Content-Type': "application/json",
          'Authorization': `Bearer ${token}` 
        } 
      });

      console.log(response.data.data);
      setAfiliadoValorUm(response.data.data);
      setValor("R$ " + formatMoney(response.data.data[0].value_money));
      setDesconto(formatMoney(response.data.data[0].discount) + " %");
      setPorcentagem(formatMoney(response.data.data[0].porcentage) + " %");

      setModalEditValoresAfiliado(true);
    }

    async function deleteAfiliadoValue(idValue){
      const response = await api.put(baseRoute + "/values/delete" , {
        id: idValue
      },{ 
        headers: { 
          'Content-Type': "application/json",
          'Authorization': `Bearer ${token}` 
        } 
      });

      if(response.data.success == true){
        mixin('delete');
        handleModalSeen(id);
      }else{
          Swal.fire('Atenção', response.data.message, 'error');
          //mixin('error');
      }
    }
    
    function deleteAfiliadoValueAlert(id){
      Swal.fire({
        title: 'Você tem certeza?',
        text: "Essa ação não poderá ser desfeita!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, delete!',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.value) {
          deleteAfiliadoValue(id);
        }
      })
    }


   
    
    return (
        <>
        <div>

        <Modal
            size="lg"
            show={modalAddValoresAfiliado}
            onHide={() => setModalAddValoresAfiliado(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >                
            <Form onSubmit={handleSubmit(onSubmitAddValoresAfiliado)} autoComplete="off" className="needs-validation">
                <Modal.Header closeButton={false}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Adicionar
                    </Modal.Title>
                </Modal.Header>                
                <Modal.Body>
                  <Row form>
                  <Col md={6} className="mb-3">
                      <Label for="name"> Valor </Label>
                      <NumberFormat
                        decimalScale={2}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        fixedDecimalScale={true}
                        prefix={"R$ "}
                        className="form-control"
                        id="transfer_value"
                        name="transfer_value"
                        onChange={(e) => setValor(e.target.value)}
                      />
                  </Col> 
                  <Col md={6} className="mb-3">
                      <Label for="name"> Porcentagem </Label>
                      <NumberFormat
                        decimalScale={2}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        fixedDecimalScale={true}
                        suffix={" %"}
                        isAllowed={(values) => {
                          const {floatValue} = values;
                          return floatValue <= 100;
                          }}
                        className="form-control"
                        id="transfer_value"
                        name="transfer_value"
                        onChange={(e) => setPorcentagem(e.target.value)}
                      />
                  </Col> 

                  <Col md={6} className="mb-3">
                      <Label for="name"> Desconto </Label>
                      <NumberFormat
                        decimalScale={2}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        fixedDecimalScale={true}
                        suffix={" %"}
                        isAllowed={(values) => {
                          const {floatValue} = values;
                          return floatValue <= 100;
                          }}
                        className="form-control"
                        id="transfer_value"
                        name="transfer_value"
                        onChange={(e) => setDesconto(e.target.value)}
                      />
                  </Col> 

                  <Col md={6} className="mb-3">
                      <Label for="active"> Situação </Label>
                      <select name="active" ref={register}  className="form-control" id="active" required>
                          <option disable selected value="">Selecione...</option>
                          <option value="1"> Ativo </option>
                          <option value="0"> Inativo </option>                                    
                      </select>
                  </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>  
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button color="secondary" onClick={ () => setModalAddValoresAfiliado('', false) } >Fechar</Button>
                </Modal.Footer>  
            </Form>
        </Modal>

          <Modal
            size="xl"
            show={ModalEditValoresAfiliado}
            onHide={() => setModalEditValoresAfiliado(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >                
            <Form onSubmit={handleSubmit(onSubmitEditValoresAfiliado)} autoComplete="off" className="needs-validation">
                <Modal.Header closeButton={false}>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Editar
                    </Modal.Title>
                </Modal.Header>                
                <Modal.Body>
                {afiliadoValorUm.map(one => (
                  <Row form>
                    <Col md={6} className="mb-3">
                        <Label for="name"> Valor </Label>
                        <NumberFormat
                          decimalScale={2}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          fixedDecimalScale={true}
                          defaultValue={formatMoney(one.value_money)}
                          prefix={"R$ "}
                          className="form-control"
                          id="transfer_value"
                          name="transfer_value"
                          onChange={(e) => setValor(e.target.value)}
                        />
                    </Col> 
                    <Col md={6} className="mb-3">
                        <Label for="name"> Porcentagem </Label>
                        <NumberFormat
                          decimalScale={2}
                          thousandSeparator={"."}
                          defaultValue={formatMoney(one.porcentage)}
                          decimalSeparator={","}
                          fixedDecimalScale={true}
                          suffix={" %"}
                          isAllowed={(values) => {
                            const {floatValue} = values;
                            return floatValue <= 100;
                            }}
                          className="form-control"
                          id="transfer_value"
                          name="transfer_value"
                          onChange={(e) => setPorcentagem(e.target.value)}
                        />
                    </Col> 

                    <Col md={6} className="mb-3">
                        <Label for="name"> Desconto </Label>
                        <NumberFormat
                          decimalScale={2}
                          thousandSeparator={"."}
                          defaultValue={formatMoney(one.discount)}
                          decimalSeparator={","}
                          fixedDecimalScale={true}
                          suffix={" %"}
                          isAllowed={(values) => {
                            const {floatValue} = values;
                            return floatValue <= 100;
                            }}
                          className="form-control"
                          id="transfer_value"
                          name="transfer_value"
                          onChange={(e) => setDesconto(e.target.value)}
                        />
                    </Col> 

                    <Col md={6} className="mb-3">
                        <Label for="active"> Situação </Label>
                        <select name="active" ref={register} defaultValue={one.active}  className="form-control" id="active" required>
                            <option disable selected value="">Selecione...</option>
                            <option value="1"> Ativo </option>
                            <option value="0"> Inativo </option>                                    
                        </select>
                    </Col>
                  </Row>
                ))}
                </Modal.Body>
                <Modal.Footer>  
                    <Button color="primary" type="submit">Enviar</Button>
                    <Button color="secondary" onClick={ () => setModalEditValoresAfiliado(false) } >Fechar</Button>
                </Modal.Footer>  
            </Form>
        </Modal>

          <Modal
            size="xl"
            show={modalOpenSeen}
            onHide={() => setModalOpenSeen(false)}
            aria-labelledby="example-modal-sizes-title-lg"
            >                
            <Modal.Header closeButton={false}>
              <Modal.Title id="example-modal-sizes-title-lg">
                  Informações
              </Modal.Title>
            </Modal.Header>                
            <Modal.Body>
     
            <Row>
                <Col sm="12">
                    <Card className="iq-card">
                        <CardHeader className="iq-card-header d-flex justify-content-between">
                            <CardTitle className="iq-header-title">
                                <h4 className="card-title"> Afiliados </h4>
                            </CardTitle>
                            <span className="float-right" >
                                {/* <Button color="warning" className="btn-filter"  type="submit"><i className="ri-add-circle-line" /> Abrir Filtro</Button> */}
                                <Button color="primary" onClick={ () => setModalAddValoresAfiliado(true) } type="submit"><i className="ri-add-circle-line" /> Adicionar</Button>
                            </span>
                        </CardHeader>
                        <CardBody className="iq-card-body">
                            <Table responsive striped className="table">
                              <thead>
                                <th scope="col"> Valor </th>
                                <th scope="col" className="text-center"> Porcentagem </th>
                                <th scope="col" className="text-center"> Desconto </th>
                                <th scope="col" className="text-center"> Ativo </th>
                                <th scope="col" className="text-center"> Editar </th>
                                <th scope="col" className="text-center"> Remover </th>
                              </thead>
                              <tbody>
                                {comissoes.map(sys => (
                                  <>
                               
                                    <tr >
                                      <td> R${formatMoney(sys.value_money)}  </td>
                                      <td className="text-center"> {formatMoney(sys.porcentage)} % </td>
                                      <td className="text-center"> {formatMoney(sys.discount)} % </td>
                                      <td className="text-center"> {sys.active_name} </td>
                                      <td className="Tabela_editar">
                                          <Button outline color="success" onClick={ () => handleModalEditAfiliadoValor(sys.id) } className="btn-fintoro"><i className="ri-edit-box-line" /></Button>
                                      </td>
                                      <td className="Tabela_editar">
                                          <Button outline color="danger" onClick={ () => deleteAfiliadoValueAlert(sys.id) } className="btn-fintoro btn-centered-times"><FontAwesomeIcon icon={faTimes} /></Button>
                                      </td>
                                    </tr>
                     
                                  </>                                
                                ))} 
                              </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
 
            </Modal.Body>
            <Modal.Footer>  
              <Button color="secondary" onClick={ () => setModalOpenSeen(false)} >Fechar</Button>
            </Modal.Footer>  
          </Modal>
            
            <Modal
                size="xl"
                show={ModalOpen}
                onHide={() => setModalOpen(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Adicionar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                        <Row form>
                        <Col md={3} className="mb-3">
                            <Label for="name"> Nome </Label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="nome" 
                                name="name"
                                ref={register}                                
                                required 
                            />
                        </Col> 
                        <Col md={3} className="mb-3">
                            <Label for="name"> Sobrenome </Label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name" 
                                name="last_name"
                                ref={register}                                
                                required 
                            />
                        </Col> 

                        <Col md={2} className="mb-3">
                            <Label for="name"> CPF </Label>
                            <InputMask   
                              mask="999.999.999-99"                                                     
                              type="text"                                                        
                              className="form-control" 
                              onChange={(e) => setCPF(e.target.value)}
                              id="cpf"                           
                              required 
                            />
                        </Col>

                        
                        <Col md={2} className="mb-3">
                            <Label for="name"> Celular </Label>
                            <InputMask   
                              mask="(99) 99999-9999"                                                     
                              type="text"                                                        
                              className="form-control" 
                              onChange={(e) => setPhone(e.target.value)}
                              id="phone"                           
                              required 
                            />
                        </Col>

                        <Col md={2} className="mb-3">
                            <Label for="active"> Situação </Label>
                            <select name="active" ref={register} className="form-control" id="active" required>
                                <option disable selected value="">Selecione...</option>
                                <option value="1"> Ativo </option>
                                <option value="0"> Inativo </option>                                    
                            </select>
                        </Col>

                        
                        <Col md={6} className="mb-3">
                            <Label for="name"> E-mail </Label>
                            <input 
                                type="email" 
                                className="form-control" 
                                id="name" 
                                name="email"
                                ref={register}                                
                                required 
                            />
                        </Col>

                        <Col md={6} className="mb-3">
                            <Label for="name"> Voucher </Label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name" 
                                name="voucher"
                                ref={register}                                
                                required 
                                onInput={toInputUppercase}
                            />
                        </Col>

                        <Col md={4} className="mb-3">
                            <Label for="name"> Banco </Label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name" 
                                name="bank"
                                ref={register}                                
                                required 
                            />
                        </Col> 

                        <Col md={4} className="mb-3">
                            <Label for="name"> Agência <small>(apenas números)</small> </Label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="name" 
                                name="agency"
                                ref={register}                                
                                required 
                            />
                        </Col> 

                        <Col md={4} className="mb-3">
                            <Label for="name"> Conta <small>(apenas números)</small> </Label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="name" 
                                name="current_account"
                                ref={register}                                
                                required 
                            />
                        </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => handleModalAdd(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>

            <Modal
                size="xl"
                show={ModalOpenEdit}
                onHide={() => setModalOpenEdit(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmitEdit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Editar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                    {systemOne.map(one => (
                         <Row form>
                        

                         <Col md={3} className="mb-3">
                             <Label for="name"> Nome </Label>
                             <input 
                                 type="text" 
                                 className="form-control" 
                                 id="nome" 
                                 defaultValue={one.name}
                                 name="name"
                                 ref={register}                                
                                 required 
                             />
                         </Col> 
                         <Col md={3} className="mb-3">
                             <Label for="name"> Sobrenome </Label>
                             <input 
                                 type="text" 
                                 className="form-control"
                                 defaultValue={one.last_name}
                                 id="name" 
                                 name="last_name"
                                 ref={register}                                
                                 required 
                             />
                         </Col> 
 
                         <Col md={2} className="mb-3">
                             <Label for="name"> CPF </Label>
                             <InputMask   
                               mask="999.999.999-99"
                               defaultValue={one.cpf}                                                     
                               type="text"     
                               readOnly                                                   
                               className="form-control" 
                               onChange={(e) => setCPF(e.target.value)}
                               id="cpf"                           
                               required 
                             />
                         </Col>
 
                         
                         <Col md={2} className="mb-3">
                             <Label for="name"> Celular </Label>
                             <InputMask   
                               mask="(99) 99999-9999" 
                               defaultValue={one.phone}                                                    
                               type="text"                                                        
                               className="form-control" 
                               onChange={(e) => setPhone(e.target.value)}
                               id="phone"                           
                               required 
                             />
                         </Col>
 
                         <Col md={2} className="mb-3">
                             <Label for="active"> Situação </Label>
                             <select name="active" ref={register} defaultValue={one.active} className="form-control" id="active" required>
                                 <option disable selected value="">Selecione...</option>
                                 <option value="1"> Ativo </option>
                                 <option value="0"> Inativo </option>                                    
                             </select>
                         </Col>
 
                         
                         <Col md={6} className="mb-3">
                             <Label for="name"> E-mail </Label>
                             <input 
                                 type="email" 
                                 className="form-control" 
                                 id="name" 
                                 defaultValue={one.email}
                                 name="email"
                                 ref={register}                                
                                 required 
                             />
                         </Col>
 
                         <Col md={6} className="mb-3">
                             <Label for="name"> Voucher </Label>
                             <input 
                                 type="text" 
                                 className="form-control" 
                                 id="name" 
                                 defaultValue={one.voucher}
                                 name="voucher"
                                 ref={register}                                
                                 required 
                                 onInput={toInputUppercase}
                             />
                         </Col>
 
                         <Col md={4} className="mb-3">
                             <Label for="name"> Banco </Label>
                             <input 
                                 type="text" 
                                 className="form-control" 
                                 id="name" 
                                 defaultValue={one.bank}
                                 name="bank"
                                 ref={register}                                
                                 required 
                             />
                         </Col> 
 
                         <Col md={4} className="mb-3">
                             <Label for="name"> Agência <small>(apenas números)</small> </Label>
                             <input 
                                 type="number" 
                                 className="form-control" 
                                 defaultValue={one.agency}
                                 id="name" 
                                 name="agency"
                                 ref={register}                                
                                 required 
                             />
                         </Col> 
 
                         <Col md={4} className="mb-3">
                             <Label for="name"> Conta <small>(apenas números)</small> </Label>
                             <input 
                                 type="number" 
                                 className="form-control" 
                                 defaultValue={one.current_account}
                                 id="name" 
                                 name="current_account"
                                 ref={register}                                
                                 required 
                             />
                         </Col>
                         </Row>
                    ))}
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => setModalOpenEdit(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>
        </div>        


            <Row>
                <Col sm="12">
                    <Card className="iq-card">
                        <CardHeader className="iq-card-header d-flex justify-content-between">
                            <CardTitle className="iq-header-title">
                                <h4 className="card-title"> Afiliados </h4>
                            </CardTitle>
                            <span className="float-right" >
                                {/* <Button color="warning" className="btn-filter"  type="submit"><i className="ri-add-circle-line" /> Abrir Filtro</Button> */}
                                <Button color="primary" onClick={ () => handleModalAdd(true) } type="submit"><i className="ri-add-circle-line" /> Adicionar</Button>
                            </span>
                        </CardHeader>
                        <CardBody className="iq-card-body">
                            <Table responsive striped className="table">
                              <thead>
                                <th scope="col"> Nome Completo </th>
                                <th scope="col" className="text-center"> CPF </th>
                                <th scope="col" className="text-center"> E-Mail </th>
                                <th scope="col" className="text-center"> Celular </th>
                                <th scope="col" className="text-center"> Banco </th>
                                <th scope="col" className="text-center"> Agência </th>
                                <th scope="col" className="text-center"> Conta </th>
                                <th scope="col" className="text-center"> Ativo </th>
                                <th scope="col" className="text-center"> Ver comissões </th>
                                <th scope="col" className="text-center"> Editar </th>
                                <th scope="col" className="text-center"> Remover </th>
                              </thead>
                              <tbody>
                                {system.map(sys => (
                                  <>
                               
                                    <tr >
                                      <td> {sys.name} {sys.last_name} </td>
                                      <td className="text-center"> {sys.cpf} </td>
                                      <td className="text-center"> {sys.email} </td>
                                      <td className="text-center"> {sys.phone} </td>
                                      <td className="text-center"> {sys.agency} </td>
                                      <td className="text-center"> {sys.current_account} </td>
                                      <td className="text-center"> {sys.bank} </td>
                                      <td className="text-center"> {sys.active_name} </td>
                                      <td className="Tabela_editar">
                                          <Button outline color="info" onClick={ () => handleModalSeen(sys.id) } className="btn-fintoro btn-centered-eye"><FontAwesomeIcon icon={faEye} /></Button>
                                      </td>
                                      <td className="Tabela_editar">
                                          <Button outline color="success" onClick={ () => handleModalEditar(sys.id, "edit") } className="btn-fintoro"><i className="ri-edit-box-line" /></Button>
                                      </td>
                                      <td className="Tabela_editar">
                                          <Button outline color="danger" onClick={ () => deleteAfiliadoAlert(sys.id) } className="btn-fintoro btn-centered-times"><FontAwesomeIcon icon={faTimes} /></Button>
                                      </td>
                                    </tr>
                     
                                  </>                                
                                ))} 
                              </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            { (pagination == 1) ? "" :
        <Pagination className={"justify-content-end"}>
          <Pagination.Item>{"Anterior"}</Pagination.Item>
          {       
          [...Array(pagination)].map((x, i) =>
            <Pagination.Item onClick={() => ((i+1))} key={i}>{(i+1)}</Pagination.Item>
          )} 
          <Pagination.Item >{"Próximo"}</Pagination.Item>
        </Pagination>
        }  
        </>
    );

}