import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, Form, Label } from "reactstrap";
import { index } from "../../../config/pluginsInit";
import { useForm } from 'react-hook-form';
import { Modal } from "react-bootstrap";
import api from '../../../services/Api';

import { Pagination } from 'react-bootstrap';

import mixin from '../../../Utils/mixin';
import Swal from 'sweetalert2';

import './Styled.css';

export default function Respostas() {
    const arr = JSON.parse(localStorage.getItem('credentials'));
    const token = arr.token;
    const baseRoute = "/responses/";
    const queRoute  = "/question/";
    const catRoute  = "/question-category/";
    const docRoute  = "/documents/";
    
    const {register, handleSubmit} = useForm();
    
    const [getId, setId] = useState([]);
    const [system, setSystem] = useState([]);
    const [systemOne, setSystemOne] = useState([]);
    const [systemCat, setSystemCat] = useState([]);
    const [systemQue, setSystemQue] = useState([]);
    const [systemDoc, setSystemDoc] = useState([]);

    const [pagination, setPagination] = useState([]);

    const [ModalOpen, setModalOpen] = React.useState(false); // ABRE E FECHA O MODAL
    const [ModalOpenEdit, setModalOpenEdit] = React.useState(false); // ABRE E FECHA O MODAL

    const [ModalToggle, setModalToggle] = React.useState('adicionar'); // ABRE E FECHA O MODAL
    const [ModalToggleEdit, setModalToggleEdit] = React.useState('editar'); // ABRE E FECHA O MODAL

    // lista os dados na tabela
    useEffect(() => {
      async function autoCat(){
        const response = await api.get(catRoute, {}, { 
          headers: { 
            'Content-Type': "application/json",
            'Authorization': `Bearer ${token}` 
          } 
        }); 
        console.log('resposta: ', response); 
        setSystemCat(response.data.data);
      }


        autoload();
        autoCat();
        autoDoc()
        autoQue(); //roda questions

        index(); // fecha o loading

    }, []);

    // adicionar 
    async function handleModalAdd(acao){ setModalOpen(acao) }  // modal

    async function autoload(){const response = await api.get(baseRoute); setSystem(response.data.data);}
    
    async function autoQue(){const response = await api.get(queRoute); setSystemQue(response.data.data);}
    async function autoDoc(){const response = await api.get(docRoute); setSystemDoc(response.data.data);}

    const onSubmit = async (data) => {
        
        const user_id = 2;
    
        const response = await api.post(baseRoute, {  
          order_id: data.ordem,	
          question_id: data.pergunta,
          name: data.name,
          complementary_questionnaire: data.questionario,
          text_active: data.texto,
          level: data.nivel,
          active: data.situacao,
          doc_id1:  data.doc1,
          doc_id2: data.doc2,
          doc_id3: data.doc3,
          doc_id4: data.doc4,
          doc_id5: data.doc5 
        });

        if(response.data.success == true){
            mixin('add');
            autoload();
            setModalOpen(false);
        }else{
            mixin('error');
        }
    }   
    
    // editar  
    async function handleModalEditar(id, acao){ 
      const response = await api.get(baseRoute +   id);
      setSystemOne(response.data.data)
      console.log(response.data.data)
      
      setId(id);
      setModalOpenEdit(acao);
    } 

    const onSubmitEdit = async (data) => {    

      const user_id = 2;

      const response = await api.put(baseRoute + getId, {
          order_id: data.ordem,	
          question_id: data.pergunta,
          name: data.name,
          complementary_questionnaire: data.questionario,
          text_active: data.texto,
          level: data.nivel,
          active: data.situacao,
          doc_id1:  data.doc1,
          doc_id2: data.doc2,
          doc_id3: data.doc3,
          doc_id4: data.doc4,
          doc_id5: data.doc5 
      });

      if(response.data.success == true){
        mixin('edit');
        autoload();
        setModalOpenEdit(false);
      }else{
        mixin('error');
      }

    }      
    
    // deletar
    async function handleDeletePeca(id){
        
      const user_id = 2;

      Swal.fire({
          title: 'Tem certeza?',
          text: "Essa ação não pode ser desfeita!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Deletar',
          cancelButtonText: "Cancelar",
      }).then((result) => {
          if (result.value) {

          async function deleteAcao(){
              const response = await api.delete(`/species/${id}`);  
          }

          deleteAcao();
          }
      })
    }

    const [ModalOpenSee, setModalOpenSee] = useState(false);
    const [systemSee, setSystemSee] = useState([]);


    async function handleModalOpenSee(id, val){
      const res = await api.get(baseRoute + id);
      setSystemSee(res.data.data);

      setModalOpenSee(val);
    }

    return (
        <>
        <div>

        <Modal
          size="lg"
          show={ModalOpenSee}
          onHide={() => setModalOpenSee(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          >                
          <Modal.Header closeButton={false}>
              <Modal.Title id="example-modal-sizes-title-lg">
                  Veja a pergunta
              </Modal.Title>
          </Modal.Header>                
          <Modal.Body>
          <Row form>
              <Col md={12} className="centered mb-3">
                {systemSee.map(see => (
                  
                  <>
                  <div className="name-question">
                    <strong>Nome:</strong> <span>{see.name}</span>
                  </div>
                  <div className="name-question">
                    <strong>Questionário Complementar:</strong> <span>{see.complementary_questionnaire_limit}</span>
                  </div>

                  <div className="name-question">
                    <strong>Pergunta:</strong> <span>{see.question_name}</span>
                  </div>

                  <div className="name-question">
                    <strong>Nível:</strong> <span>{see.level}</span>
                  </div>

                  <div className="name-question">
                    <strong>Texto Ativo:</strong> <span>{see.text_active}</span>
                  </div>

                  <div className="name-question">
                    <strong>Situação:</strong> <span>{see.active_name}</span>
                  </div>                 
                  </>
                ))}
                  
              </Col> 
          </Row>
          </Modal.Body>
          <Modal.Footer>  
              {/* <Button color="primary" type="submit">Enviar</Button> */}
              <Button color="secondary" onClick={ () => setModalOpenSee(false) } >Fechar</Button>
          </Modal.Footer>  

      </Modal>
            
            <Modal
                size="lg"
                show={ModalOpen}
                onHide={() => setModalOpen(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Adicionar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                        <Row form>
                          <Col md={2} className="mb-3">
                            <Label for="name"> Ordem ID </Label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="name" 
                                name="ordem"
                                ref={register}                                
                                required />
                            </Col>
                            <Col md={10} className="mb-3">
                                <Label for="active"> Pergunta </Label>
                                <select name="pergunta" ref={register} className="form-control optionBreaked" required>
                                    <option disable selected value="">Selecione...</option>
                                    {systemQue.map(que => (
                                      <option value={que.id}> {que.name} </option> 
                                    ))}
                                                                      
                                </select>
                            </Col> 
                            <Col md={12} className="mb-3">
                                <Label for="name"> Nome </Label>
                                <input 
                                    type="text" 
                                    className="form-control" 
                                    id="name" 
                                    name="name"
                                    ref={register}                                
                                    required />
                            </Col> 
                            <Col md={12} className="mb-3">
                                <Label for="name"> Questionário Complementar </Label>
                                <textarea 
                                    type="text" 
                                    className="form-control" 
                                    id="question" 
                                    name="questionario"
                                    ref={register}                                
                                  >
                                  </textarea>
                            </Col>                            
                            <Col md={8} className="mb-3">
                                <Label for="active"> Texto Ativo </Label>
                                <input 
                                  type="text" 
                                  className="form-control" 
                                  id="texto" 
                                  name="texto"
                                  maxLength="255"
                                  ref={register}                                 
                                />
                            </Col> 

                            <Col md={2} className="mb-3">
                                <Label for="active"> Nível </Label>
                                <select name="nivel" ref={register} className="form-control"  required>
                                    <option disable selected value="">Selecione...</option>
                                    <option value="1"> Fácil </option>
                                    <option value="2"> Médio </option>
                                    <option value="3"> Difícil </option>                                    
                                </select>
                            </Col> 

                            <Col md={2} className="mb-3">
                                <Label for="active"> Situação </Label>
                                <select name="situacao" ref={register} className="form-control" required>
                                    <option disable selected value="">Selecione...</option>
                                    <option value="1"> Ativo </option>
                                    <option value="0"> Inativo </option>                                    
                                </select>
                            </Col> 

                            <Col md={12} className="mb-3">
                                <Label for="active"> Documento(s) Vincular 1 </Label>
                                <select name="doc1" ref={register} className="form-control"  >
                                    <option disable selected value="">Selecione...</option>
                                    {systemDoc.map(doc => (
                                      <option value={doc.id}> {doc.name} </option> 
                                    ))}                            
                                </select>
                            </Col>

                            <Col md={12} className="mb-3">
                                <Label for="active"> Documento(s) Vincular 2 </Label>
                                <select name="doc2" ref={register} className="form-control"  >
                                    <option disable selected value="">Selecione...</option>
                                    {systemDoc.map(doc => (
                                      <option value={doc.id}> {doc.name} </option> 
                                    ))}                            
                                </select>
                            </Col> 

                            <Col md={12} className="mb-3">
                                <Label for="active"> Documento(s) Vincular 3 </Label>
                                <select name="doc3" ref={register} className="form-control"  >
                                    <option disable selected value="">Selecione...</option>
                                    {systemDoc.map(doc => (
                                      <option value={doc.id}> {doc.name} </option> 
                                    ))}                            
                                </select>
                            </Col> 

                            <Col md={12} className="mb-3">
                                <Label for="active"> Documento(s) Vincular 4 </Label>
                                <select name="doc4" ref={register} className="form-control"  >
                                    <option disable selected value="">Selecione...</option>
                                    {systemDoc.map(doc => (
                                      <option value={doc.id}> {doc.name} </option> 
                                    ))}                            
                                </select>
                            </Col> 

                            <Col md={12} className="mb-3">
                                <Label for="active"> Documento(s) Vincular 5 </Label>
                                <select name="doc5" ref={register} className="form-control"  >
                                    <option disable selected value="">Selecione...</option>
                                    {systemDoc.map(doc => (
                                      <option value={doc.id}> {doc.name} </option> 
                                    ))}                            
                                </select>
                            </Col> 

                        </Row>
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => handleModalAdd(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>

            <Modal
                size="lg"
                show={ModalOpenEdit}
                onHide={() => setModalOpenEdit(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmitEdit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Editar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                    {systemOne.map(one => (
                      <Row form>
                      <Col md={2} className="mb-3">
                            <Label for="name"> Ordem ID </Label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="name" 
                                name="ordem"
                                ref={register}       
                                defaultValue={one.order_id}                         
                                required />
                            </Col>
                            <Col md={10} className="mb-3">
                          <Label for="active"> Pergunta </Label>
                          <select defaultValue={one.question_id} name="pergunta" ref={register} className="form-control optionBreaked" required>
                              <option disable selected value="">Selecione...</option>
                              {systemQue.map(que => (
                                <option value={que.id}> {que.name} </option> 
                              ))}
                                                                
                          </select>
                      </Col> 
                      <Col md={12} className="mb-3">
                          <Label for="name"> Nome </Label>
                          <input 
                              type="text" 
                              className="form-control" 
                              id="name" 
                              name="name"
                              defaultValue={one.name}
                              ref={register}                                
                              required />
                      </Col> 
                      <Col md={12} className="mb-3">
                          <Label for="name"> Questionário Complementar </Label>
                            <textarea 
                              type="text" 
                              className="form-control" 
                              id="question" 
                              defaultValue={one.complementary_questionnaire}
                              name="questionario"
                              ref={register}                                
                            >
                            </textarea>
                      </Col>                            
                      <Col md={8} className="mb-3">
                          <Label for="active"> Texto Ativo </Label>
                            <input 
                              type="text" 
                              className="form-control" 
                              id="texto" 
                              defaultValue={one.text_active}
                              name="texto"
                              maxLength="255"
                              ref={register}                                
                            />
                      </Col> 

                      <Col md={2} className="mb-3">
                          <Label for="active"> Nível </Label>
                          <select defaultValue={one.level} name="nivel" ref={register} className="form-control"  required>
                              <option disable selected value="">Selecione...</option>
                              <option value="1"> Fácil </option>
                              <option value="2"> Médio </option>
                              <option value="3"> Difícil </option>                                    
                          </select>
                      </Col> 

                      <Col md={2} className="mb-3">
                          <Label for="active"> Situação </Label>
                          <select defaultValue={one.active} name="situacao" ref={register} className="form-control" required>
                              <option disable selected value="">Selecione...</option>
                              <option value="1"> Ativo </option>
                              <option value="0"> Inativo </option>                                    
                          </select>
                      </Col> 

                      <Col md={12} className="mb-3">
                          <Label for="active"> Documento(s) Vincular 1 </Label>
                          <select defaultValue={one.doc_id1} name="doc1" ref={register} className="form-control" >
                              <option disable selected value="">Selecione...</option>
                              {systemDoc.map(doc => (
                                <option value={doc.id}> {doc.name} </option> 
                              ))}                            
                          </select>
                      </Col>

                      <Col md={12} className="mb-3">
                          <Label for="active"> Documento(s) Vincular 2 </Label>
                          <select defaultValue={one.doc_id2} name="doc2" ref={register} className="form-control"  >
                              <option disable selected value="">Selecione...</option>
                              {systemDoc.map(doc => (
                                <option value={doc.id}> {doc.name} </option> 
                              ))}                            
                          </select>
                      </Col> 

                      <Col md={12} className="mb-3">
                          <Label for="active"> Documento(s) Vincular 3 </Label>
                          <select defaultValue={one.doc_id3} name="doc3" ref={register} className="form-control"  >
                              <option disable selected value="">Selecione...</option>
                              {systemDoc.map(doc => (
                                <option value={doc.id}> {doc.name} </option> 
                              ))}                            
                          </select>
                      </Col> 

                      <Col md={12} className="mb-3">
                          <Label for="active"> Documento(s) Vincular 4 </Label>
                          <select defaultValue={one.doc_id4} name="doc4" ref={register} className="form-control"  >
                              <option disable selected value="">Selecione...</option>
                              {systemDoc.map(doc => (
                                <option value={doc.id}> {doc.name} </option> 
                              ))}                            
                          </select>
                      </Col> 

                      <Col md={12} className="mb-3">
                          <Label for="active"> Documento(s) Vincular 5 </Label>
                          <select defaultValue={one.doc_id5} name="doc5" ref={register} className="form-control"  >
                              <option disable selected value="">Selecione...</option>
                              {systemDoc.map(doc => (
                                <option value={doc.id}> {doc.name} </option> 
                              ))}                            
                          </select>
                      </Col> 

                  </Row>
                    ))}
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => setModalOpenEdit(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>
        </div>        


            <Row>
                <Col sm="12">
                    <Card className="iq-card">
                        <CardHeader className="iq-card-header d-flex justify-content-between">
                            <CardTitle className="iq-header-title">
                                <h4 className="card-title"> Respostas </h4>
                            </CardTitle>
                            <span className="float-right" >
                                {/* <Button color="warning" className="btn-filter"  type="submit"><i className="ri-add-circle-line" /> Abrir Filtro</Button> */}
                                <Button color="primary" onClick={ () => handleModalAdd(true) } type="submit"><i className="ri-add-circle-line" /> Adicionar</Button>
                            </span>
                        </CardHeader>
                        {/* <CardBody>
                        
                                <input 
                                    type="text" 
                                    maxLength="255"
                                    minLength="2"                                    
                                    className="form-control" 
                                    placeholder="Digite para pesquisar..."
                                     />
                            
                        </CardBody> */}
                        <CardBody className="iq-card-body">
                        <Table responsive striped className="table">
                                <thead>
                                    <th scope="col" className="text-center"> ID </th>
                                    <th scope="col"> Nome </th>
                                    <th scope="col" className="text-center"> Questionário Complementar </th>
                                    <th scope="col" className="text-center"> Pergunta </th>
                                    <th scope="col" className="text-center"> Nível </th>
                                    <th scope="col" className="text-center"> Texto ativo </th>
                                    <th scope="col" className="text-center"> Ativo </th>
                                    <th scope="col" className="text-center"> Ver </th>
                                    <th scope="col" className="text-center"> Editar </th>
                                </thead>
                                <tbody>
                                {system.map(sys => (
                                  <tr >
                                      <td className="Tabela_id"> {sys.id} </td>
                                      <td> {sys.name} </td>
                                      <td className="text-center"> {sys.complementary_questionnaire_limit}Questionário </td>
                                      <td className="text-center"> {sys.question_name} </td>
                                      <td className="text-center">{sys.level}  </td>
                                      <td className="text-center"> {sys.text_active} </td>
                                      <td className="text-center">{sys.active_name}  </td>
                                      <td className="Tabela_apagar">
                                          <Button outline color="info" onClick={() => handleModalOpenSee(sys.id, true)} className="btn-fintoro"><i class="far fa-eye"></i></Button>                                            
                                      </td>
                                      <td className="Tabela_editar">
                                          <Button outline color="success" onClick={ () => handleModalEditar(sys.id, true) } className="btn-fintoro"><i className="ri-edit-box-line" /></Button>
                                      </td>
                                  </tr>
                                ))}
                               
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            { (pagination == 1) ? "" :
        <Pagination className={"justify-content-end"}>
          <Pagination.Item>{"Anterior"}</Pagination.Item>
          {       
          [...Array(pagination)].map((x, i) =>
            <Pagination.Item onClick={() => ((i+1))} key={i}>{(i+1)}</Pagination.Item>
          )} 
          <Pagination.Item >{"Próximo"}</Pagination.Item>
        </Pagination>
        }  
        </>
    );

}