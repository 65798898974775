import React, {useEffect} from "react";
import {index} from "../../../config/pluginsInit";
import {Button, Card, CardBody, Col, Row, Table} from "reactstrap";

const Index = props => {

    useEffect(() => {
        index();
    });

    return (
        <Row>
            <Col md={12}>
                <Card>
                    <CardBody>
                        <div className={"table-responsive"}>
                            <Table>
                                <thead>
                                <tr>
                                    <th className="text-center" scope="col" />
                                    <th className="text-center" scope="col">Starter</th>
                                    <th className="text-center" scope="col">Business</th>
                                    <th className="text-center" scope="col">Enterprise</th>
                                    <th className="text-center" scope="col">Unlimited</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <th className="text-center" scope="row">Email support</th>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center" scope="row">UI Kit</th>
                                    <td className="text-center"></td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center" scope="row">100% support</th>
                                    <td className="text-center" />
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center" scope="row">Advance form</th>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center" scope="row">Custom shortcode</th>
                                    <td className="text-center" />
                                    <td className="text-center" />
                                    <td className="text-center" />
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <th className="text-center" scope="row">Thousand of Widgets</th>
                                    <td className="text-center" />
                                    <td className="text-center" />
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                    <td className="text-center">
                                        <i className="ri-check-line ri-2x text-success" />
                                    </td>
                                </tr>
                                <tr>
                                    <td className="text-center" />
                                    <td className="text-center">
                                        <h2>$19<small> / Per month</small></h2>
                                        <Button type="button" color={"primary"} className="mt-3">Purchase</Button>
                                    </td>
                                    <td className="text-center">
                                        <h2>$39<small> / Per month</small></h2>
                                        <Button type="button" color={"primary"} className="mt-3">Purchase</Button>
                                    </td>
                                    <td className="text-center">
                                        <h2>$119<small> / Per month</small></h2>
                                        <Button type="button" color={"primary"} className="mt-3">Purchase</Button>
                                    </td>
                                    <td className="text-center">
                                        <h2>$219<small> / Per month</small></h2>
                                        <Button type="button" color={"primary"} className="mt-3">Purchase</Button>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default Index;
