import React from 'react';
import {Link, NavLink} from "react-router-dom";

const index2 = (props) => {
    const { items, display } = props; 
    console.log('items', items)
    return (
        <ul className={"iq-submenu"} style={ display !== undefined && display === true ? style : {} }>
            { items.map((item, subIndex) => (
                <li key={subIndex + "subsubmenu"}>
                    <NavLink to={ item.link } className="iq-waves-effect">
                        { item.icon && (<i className={ item.icon } />) }
                        <span>{ item.title }</span>
                    </NavLink>
                </li>
            )) }
        </ul>
    );
    };
    const style = {
    display: "block"
};

export default index2;
