import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, Form, Label } from "reactstrap";
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import api from '../../../services/Api';

export default function SignUp1() {

  function validadorCPF(strCPF) {  
    var Soma;  
    var Resto;  
    Soma = 0;  
    if (strCPF.length != 11 || 
      strCPF == "00000000000" || 
      strCPF == "11111111111" || 
      strCPF == "22222222222" || 
      strCPF == "33333333333" || 
      strCPF == "44444444444" || 
      strCPF == "55555555555" || 
      strCPF == "66666666666" || 
      strCPF == "77777777777" || 
      strCPF == "88888888888" || 
      strCPF == "99999999999")
        return false;	  
       
    for (let i=1; i<=9; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (11 - i);  
    Resto = (Soma * 10) % 11;  
     
    if ((Resto == 10) || (Resto == 11))  Resto = 0;  
    if (Resto != parseInt(strCPF.substring(9, 10)) ) return false;  
     
    Soma = 0;   
    for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i-1, i)) * (12 - i);  
    Resto = (Soma * 10) % 11;  
     
    if ((Resto == 10) || (Resto == 11))  Resto = 0;  
    if (Resto != parseInt(strCPF.substring(10, 11) ) ) return false;  
    return true; 
  }
  
    const [url, seturl] = useState('https://milcontas.com.br/termos-de-uso');
    const [cpf, setcpf] = useState();
    const {register, handleSubmit} = useForm();
    const history = useHistory();

    async function onSubmit(data){
      if(data.termos == false){
        Swal.fire('Termos de Uso', 'Para se cadastrar no nosso sistema, é necessário aceitar nossos termos de uso', 'info');
        return
      }

      if(data.senha != data.senha2){
        Swal.fire('Senhas incompatíveis', 'As senhas são incompatíveis. Por favor, reveja.', 'warning')
        return
      }

      if(cpf.includes("_")){
        Swal.fire('CPF', 'O campo CPF não foi totalmente preenchido', 'warning');
        return
      }

      const username = cpf.split('.').join("").replace('-', '')

      if(!validadorCPF(username)){
        Swal.fire('CPF', 'CPF digitado é inválido, por favor, forneça um CPF válido', 'warning');
        return
      }

      await api.post('register', {
        nome: data.nome,
	      sobrenome: data.sobrenome,
	      username: username,
	      cpf: cpf,
	      email: data.email,
	      senha: data.senha,
	      senha_confirma: data.senha2
      })
      .then(response => {
        console.log(response.data.success);
       if(response.data.success){
         Swal.fire('Cadastro realizado com sucesso', 'Aguarde, você será redirecionado para realizar o login', 'success');

         setTimeout(() => {
          history.push('/auth/sign-in1');
         }, 2000);
       }else{
         Swal.fire('CPF já cadastrado', 'Você já está cadastrado, que tal fazer login?', 'info');
       }
      })
      .catch(error => {
        //ERRO E CAPTURA
        Swal.fire('Oops', 'Houve algum erro no nosso servidor, tente novamente mais tarde.', 'error');
        console.log(error)
      });

     // console.log('passou')
      
    } 

    return (
        <>
            <div className="sign-in-from">
                <h1 className="mb-0">Crie uma Conta</h1>
                <p>Crie uma conta para acessar nosso sistema da Mil Contas.</p>
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="needs-validation">
                <Row form>
                    <Col md={6} className="mb-3">
                      <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Nome</label>
                          <input name="nome" ref={register} type="text" className="form-control mb-0" id="nome" aria-describedby="emailHelp" placeholder="Nome"  required/>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <div className="form-group">
                          <label htmlFor="exampleInputEmail1">Sobrenome</label>
                          <input name="sobrenome" ref={register} type="text" className="form-control mb-0" id="sobrenome" aria-describedby="emailHelp" placeholder="Sobrenome" required/>
                      </div>
                    </Col>
                    <Col md={8} className="mb-3">
                      <div className="form-group">
                          <label htmlFor="exampleInputEmail1">E-Mail</label>
                          <input name="email" ref={register} type="email" className="form-control mb-0" id="email" aria-describedby="emailHelp" placeholder="Digite seu melhor E-Mail" required />
                      </div>
                    </Col>
                    <Col md={4} className="mb-3">
                      <div className="form-group">
                          <label htmlFor="exampleInputEmail1">CPF</label>
                          {/* <input name="cpf" ref={register} maxLength={11} type="text" className="form-control mb-0" id="cpf" aria-describedby="emailHelp" placeholder="Digite seu CPF" required/> */}
                          <InputMask   
                              mask="999.999.999-99"                                                     
                              type="text"                                                        
                              className="form-control" 
                              onChange={(e) => setcpf(e.target.value)}
                              id="cpf"                           
                              required 
                            />
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Senha</label>
                          <input name="senha" ref={register} type="password" className="form-control mb-0" id="senha" placeholder="Digite a senha" required/>
                      </div>
                    </Col>
                    <Col md={6} className="mb-3">
                      <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Repita a senha</label>
                          <input name="senha2" ref={register} type="password" className="form-control mb-0" id="senha" placeholder="Repita a senha" required/>
                      </div>
                    </Col>
                    </Row>
                    <div className="d-inline-block w-100">
                        <div className="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                            <input name="termos" ref={register} type="checkbox" className="custom-control-input" id="customCheck1" />
                            <label className="custom-control-label" htmlFor="customCheck1">
                                Eu aceito <a target="_blank" onClick={() => window.open(url, '_blank')} href="#">Termos de Uso</a>
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary float-right">Criar conta</button>
                    </div>
                    <div className="sign-info">
                        <span className="dark-color d-inline-block line-height-2">
                            Já tem conta? <Link to="/auth/sign-in1">Acessar</Link></span>
                        <ul className="iq-social-media">
                            <li><Link to="/"><i className="ri-facebook-box-line" /></Link></li>
                            <li><Link to="/"><i className="ri-twitter-line" /></Link></li>
                            <li><Link to="/"><i className="ri-instagram-line" /></Link></li>
                        </ul>
                    </div>
                    
                </Form>
            </div>
        </>
    );
}

