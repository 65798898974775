import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, Form, Label } from "reactstrap";
import { index } from "../../../config/pluginsInit";
import { useForm } from 'react-hook-form';
import { Modal } from "react-bootstrap";
import api from '../../../services/Api';

import { Pagination } from 'react-bootstrap';

import mixin from '../../../Utils/mixin';
import Swal from 'sweetalert2';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'

import './Styled.css';

export default function Perguntas() {

    const baseRoute = "/question/";
    const catRoute = "/question-category/";
    
    const {register, handleSubmit} = useForm();
    
    const [getId, setId] = useState([]);
    const [system, setSystem] = useState([]);
    const [systemOne, setSystemOne] = useState([]);
    const [systemCat, setSystemCat] = useState([]);

    const [pagination, setPagination] = useState([]);

    const [ModalOpen, setModalOpen] = React.useState(false); // ABRE E FECHA O MODAL
    const [ModalOpenEdit, setModalOpenEdit] = React.useState(false); // ABRE E FECHA O MODAL

    const [ModalToggle, setModalToggle] = React.useState('adicionar'); // ABRE E FECHA O MODAL
    const [ModalToggleEdit, setModalToggleEdit] = React.useState('editar'); // ABRE E FECHA O MODAL

    // lista os dados na tabela
    useEffect(() => {
        autoload()
        autoCat()

        index(); // fecha o loading

    }, []);

    // adicionar 
    async function handleModalAdd(acao){ setModalOpen(acao) }  // modal

    async function autoload(){const response = await api.get(baseRoute); setSystem(response.data.data);}
    async function autoCat(){const response = await api.get(catRoute); setSystemCat(response.data.data);}

    const onSubmit = async (data) => {
        
        const user_id = 2;
    
        const response = await api.post(baseRoute, {
            order_id: data.ordem,	
            name:data.nome,
            info:data.info,
            question_cat_id: data.cat,
            question_type:data.tipo,
            declaration_type:data.dec,
            active:data.situacao
        });

        if(response.data.success == true){
            mixin('add');
            autoload();
            setModalOpen(false);
        }else{
            mixin('error');
        }
    }  
    
    // editar  
    async function handleModalEditar(id, acao){ 

        const response = await api.get(baseRoute +   id);
        setSystemOne(response.data.data)
        console.log(response.data.data)
        
        setId(id);
        setModalOpenEdit(acao);
    
    }  

    const onSubmitEdit = async (data) => {    

        const user_id = 2;

      
        const response = await api.put(baseRoute + getId, {
            order_id: data.ordem,	
            name:data.nome,
            info:data.info,
            question_cat_id: data.cat,
            question_type:data.tipo,
            declaration_type:data.dec,
            active:data.situacao
        });

        if(response.data.success == true){
            mixin('edit');
            autoload();
            setModalOpenEdit(false);
        }else{
            mixin('error');
        }

    }      
    
    // deletar
    async function handleDeletePeca(id){

        const user_id = 2;
    
        Swal.fire({
          title: 'Tem certeza?',
          text: "Essa ação não pode ser desfeita!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Deletar',
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.value) {
    
            async function deleteAcao(){
              
                const response = await api.delete(`/species/${id}`);
    
               
                
            }
    
            deleteAcao();
          }
        })
      }

    const [ModalOpenSee, setModalOpenSee] = useState(false);
    const [systemSee, setSystemSee] = useState([]);


    async function handleModalOpenSee(id, val){
      const res = await api.get(baseRoute + id);
      setSystemSee(res.data.data);

      setModalOpenSee(val);
    }

    return (
        <>
        <div>

        <Modal
          size="lg"
          show={ModalOpenSee}
          onHide={() => setModalOpenSee(false)}
          aria-labelledby="example-modal-sizes-title-lg"
          >                
          <Modal.Header closeButton={false}>
              <Modal.Title id="example-modal-sizes-title-lg">
                  Veja a pergunta
              </Modal.Title>
          </Modal.Header>                
          <Modal.Body>
          <Row form>
              <Col md={12} className="centered mb-3">
                {systemSee.map(see => (
                  
                  <>
                  <div className="name-question">
                    <strong>Nome:</strong> <span>{see.name}</span>
                  </div>
                  <div className="name-question">
                    <strong>Informação:</strong> <span>{see.info}</span>
                  </div>

                  <div className="name-question">
                    <strong>Declaração:</strong> <span>{see.declaration_type_name}</span>
                  </div>

                  <div className="name-question">
                    <strong>Situação:</strong> <span>{see.active_name}</span>
                  </div>
                  
                  </>
                ))}
                  
              </Col> 
          </Row>
          </Modal.Body>
          <Modal.Footer>  
              {/* <Button color="primary" type="submit">Enviar</Button> */}
              <Button color="secondary" onClick={ () => setModalOpenSee(false) } >Fechar</Button>
          </Modal.Footer>  

      </Modal>
            
            <Modal
                size="lg"
                show={ModalOpen}
                onHide={() => setModalOpen(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Adicionar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                    <Row form>
                        <Col md={3} className="mb-3">
                            <Label for="name"> Ordem ID </Label>
                            <input 
                                type="number" 
                                className="form-control" 
                                id="name" 
                                name="ordem"
                                ref={register}                                
                                required 
                            />
                        </Col> 

                        <Col md={9} className="mb-3">
                            <Label for="name"> Nome </Label>
                            <input 
                                type="text" 
                                className="form-control" 
                                id="name" 
                                name="nome"
                                ref={register}                                
                                required 
                            />
                        </Col> 
                        <Col md={12} className="mb-3">
                            <Label for="name"> Informação </Label>
                            <textarea 
                                type="text" 
                                
                                className="form-control" 
                                id="name" 
                                name="info"
                                ref={register}                                
                                required ></textarea>
                        </Col> 

                        <Col md={6} className="mb-3">
                            <Label for="active"> Categoria </Label>
                            <select name="cat" ref={register} className="form-control" id="tipo" required>
                                <option disable selected value="">Selecione...</option>
                                {/* {systemCat.map(cat => (
                                    <option value={cat.id}> {cat.name} </option> 
                                ))} */}
                                <option value="1"> Dependentes </option>   
                                <option value="2"> Rendimentos </option>   
                                <option value="3"> Investimentos </option>   
                                <option value="4"> Bens e Direitos </option>   
                                <option value="5"> Pagamentos </option>   
                                <option value="6"> Dívidas </option>   
                                <option value="7"> Informações Gerais </option>                           
                            </select>
                        </Col>  
                        <Col md={6} className="mb-3">
                            <Label for="active"> Tipo </Label>
                            <select name="tipo" ref={register} className="form-control" id="tipo" required>
                                <option disable selected value="">Selecione...</option>
                                <option value="1"> 1 </option>                                    
                                <option value="2"> 2 </option>                                    
                                <option value="3"> 3 </option>                                    
                                <option value="4"> 4 </option>                                    
                                <option value="5"> 5 </option>                                    
                            </select    >
                        </Col> 
                        <Col md={6} className="mb-3">
                            <Label for="active"> Declaração </Label>
                            <select name="dec" ref={register} className="form-control" id="tipo" required>
                                <option disable selected value="">Selecione...</option>                              
                                <option value="2"> Simplificada </option>                                 
                                <option value="3"> Completa </option>                                 
                            </select>
                        </Col> 
                        <Col md={6} className="mb-3">
                            <Label for="active"> Situação </Label>
                            <select name="situacao" ref={register} className="form-control" id="active" required>
                                <option disable selected value="">Selecione...</option>
                                <option value="1"> Ativo </option>
                                <option value="0"> Inativo </option>                                    
                            </select>
                        </Col>                            
                            
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => setModalOpen(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>

            <Modal
                size="lg"
                show={ModalOpenEdit}
                onHide={() => setModalOpenEdit(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                >                
                <Form onSubmit={handleSubmit(onSubmitEdit)} autoComplete="off" className="needs-validation">
                    <Modal.Header closeButton={false}>
                        <Modal.Title id="example-modal-sizes-title-lg">
                            Editar
                        </Modal.Title>
                    </Modal.Header>                
                    <Modal.Body>
                    {systemOne.map(one => (
                        <Row form>
                            <Col md={3} className="mb-3">
                            <Label for="name"> Ordem ID </Label>
                            <input 
                                type="number" 
                                defaultValue={one.order_id}
                                className="form-control" 
                                id="name" 
                                name="ordem"
                                ref={register}                                
                                required 
                            />
                        </Col> 

                        <Col md={9} className="mb-3">
                            <Label for="name"> Nome </Label>
                            <input 
                                type="text" 
                                defaultValue={one.name}
                                className="form-control" 
                                id="name" 
                                name="nome"
                                ref={register}                                
                                required 
                            />
                        </Col> 
                        <Col md={12} className="mb-3">
                            <Label for="name"> Informação </Label>
                            <textarea 
                                type="text" 
                                defaultValue={one.info}
                                className="form-control" 
                                id="name" 
                                name="info"
                                ref={register}                                
                                required ></textarea>
                        </Col> 

                        <Col md={6} className="mb-3">
                            <Label for="active"> Categoria </Label>
                            <select name="cat" defaultValue={one.question_cat_id} ref={register} className="form-control" id="tipo" required>
                                <option disable selected value="">Selecione...</option>
                                {/* {systemCat.map(cat => (
                                    <option value={cat.id}> {cat.name} </option> 
                                ))} */}

                                <option value="1"> Dependentes </option>   
                                <option value="2"> Rendimentos </option>   
                                <option value="3"> Investimentos </option>   
                                <option value="4"> Bens e Direitos </option>   
                                <option value="5"> Pagamentos </option>   
                                <option value="6"> Dívidas </option>   
                                <option value="7"> Informações Gerais </option> 
                                                                   
                            </select>
                        </Col>  
                        <Col md={6} className="mb-3">
                            <Label for="active"> Tipo </Label>
                            <select name="tipo" defaultValue={one.question_type} ref={register} className="form-control" id="tipo" required>
                                <option disable selected value="">Selecione...</option>
                                <option value="1"> 1 </option>                                    
                                <option value="2"> 2 </option>                                    
                                <option value="3"> 3 </option>                                    
                                <option value="4"> 4 </option>                                    
                                <option value="5"> 5 </option>                                    
                            </select    >
                        </Col> 
                        <Col md={6} className="mb-3">
                            <Label for="active"> Declaração </Label>
                            <select name="dec" defaultValue={one.declaration_type} ref={register} className="form-control" id="tipo" required>
                                <option disable selected value="">Selecione...</option>                                 
                                <option value="2"> Simplificada </option>                                 
                                <option value="3"> Completa </option>                                 
                            </select>
                        </Col> 
                        <Col md={6} className="mb-3">
                            <Label for="active"> Situação </Label>
                            <select name="situacao" defaultValue={one.active} ref={register} className="form-control" id="active" required>
                                <option disable selected value="">Selecione...</option>
                                <option value="1"> Ativo </option>
                                <option value="0"> Inativo </option>                                    
                            </select>
                        </Col>                            
                            
                        </Row>
                    ))}
                    </Modal.Body>
                    <Modal.Footer>  
                        <Button color="primary" type="submit">Enviar</Button>
                        <Button color="secondary" onClick={ () => setModalOpenEdit(false) } >Fechar</Button>
                    </Modal.Footer>  
                </Form>
            </Modal>
        </div>        


            <Row>
                <Col sm="12">
                    <Card className="iq-card">
                        <CardHeader className="iq-card-header d-flex justify-content-between">
                            <CardTitle className="iq-header-title">
                                <h4 className="card-title"> Perguntas </h4>
                            </CardTitle>
                            <span className="float-right" >
                                {/* <Button color="warning" className="btn-filter"  type="submit"><i className="ri-add-circle-line" /> Abrir Filtro</Button> */}
                                <Button color="primary" onClick={ () => handleModalAdd(true) } type="submit"><i className="ri-add-circle-line" /> Adicionar</Button>
                            </span>
                        </CardHeader>
                        {/* <CardBody>
                        
                                <input 
                                    type="text" 
                                    maxLength="255"
                                    minLength="2"                                    
                                    className="form-control" 
                                    placeholder="Digite para pesquisar..."
                                     />
                            
                        </CardBody> */}
                        <CardBody className="iq-card-body">
                            <Table responsive striped className="table">
                                <thead>
                                    <th scope="col" className="text-center"> ID </th>
                                    <th scope="col"> Nome </th>
                                    <th scope="col" className="text-center"> Informação </th>
                                    <th scope="col" className="text-center"> Ordem ID </th>
                                    <th scope="col" className="text-center"> Tipo </th>
                                    <th scope="col" className="text-center"> Declaração </th>
                                    <th scope="col" className="text-center"> Ativo </th>
                                    <th scope="col" className="text-center"> Ver </th>
                                    <th scope="col" className="text-center"> Editar </th>
                                    {/* <th scope="col" className="text-center"> Deletar </th> */}
                                </thead>
                                <tbody>
                                {system.map(sys => (
                                    <tr  key={sys.id}>
                                        <td className="Tabela_id"> {sys.id} </td>
                                        <td> {sys.name} </td>
                                        <td className="text-center"> {sys.info_limit} </td>
                                        <td className="text-center"> {sys.order_id} </td>
                                        <td className="text-center"> {sys.question_type} </td>
                                        <td className="text-center"> {sys.declaration_type_name} </td>
                                        <td className="text-center"> {sys.active_name} </td>
                                        <td className="Tabela_apagar">
                                            <Button outline color="info"  onClick={ () => handleModalOpenSee(sys.id, true) } className="btn-fintoro btn-centered"><i class="far fa-eye"></i></Button>                                            
                                        </td>
                                        <td className="Tabela_editar">
                                            <Button outline color="success" onClick={ () => handleModalEditar(sys.id, true) } className="btn-fintoro"><i className="ri-edit-box-line" /></Button>
                                        </td>
                                    </tr>
                                ))}                               
                               
                                </tbody>
                            </Table>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
            { (pagination == 1) ? "" :
        <Pagination className={"justify-content-end"}>
          <Pagination.Item>{"Anterior"}</Pagination.Item>
          {       
          [...Array(pagination)].map((x, i) =>
            <Pagination.Item onClick={() => ((i+1))} key={i}>{(i+1)}</Pagination.Item>
          )} 
          <Pagination.Item >{"Próximo"}</Pagination.Item>
        </Pagination>
        }  
        </>
    );

}