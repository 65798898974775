import React from 'react';

// import { Container } from './styles';
const Estados = [
    {uf: 'AC'}, {uf: 'AL'}, {uf: 'AP'}, {uf: 'AM'}, {uf: 'BA'}, {uf: 'CE'}, {uf: 'DF'}, {uf: 'ES'}, {uf: 'GO'}, {uf: 'MA'}, 
    {uf: 'MT'}, {uf: 'MS'}, {uf: 'MG'}, {uf: 'PA'}, {uf: 'PB'}, {uf: 'PR'}, {uf: 'PE'}, {uf: 'PI'}, {uf: 'RJ'}, {uf: 'RN'}, 
    {uf: 'RS'}, {uf: 'RO'}, {uf: 'RR'}, {uf: 'SC'}, {uf: 'SP'}, {uf: 'SE'}, {uf: 'TO'}
  ];

export default Estados;
