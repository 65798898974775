import Swal from 'sweetalert2';

export default function Mixin(acao) {
    let color = "rgba(54, 124, 16, 0.8)";
    if(acao == "error"){
      color = "rgba(122, 15, 44, 0.8)";
    }
    
    const Toast = Swal.mixin({
      background: color,
      buttonsStyling: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      padding: '20px',
      customClass: 'sweetMixin',
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    });

    if(acao === 'add'){
      Toast.fire({
        icon: 'success',
        title: '<span style="color: #fff">Adicionado com sucesso!</span>',
      });
    }else if(acao === 'edit'){
      Toast.fire({
        icon: 'success',
        title: '<span style="color: #fff">Atualizado com sucesso!</span>',
      });
    }else if(acao === 'delete'){
      Toast.fire({
        icon: 'success',
        title: '<span style="color: #fff">Deletado com sucesso!</span>',
      });
    }else if(acao === 'error'){
      Toast.fire({
        icon: 'error',
        title: '<span style="color: #fff">Houve algum problema!</span>',
      });
    }else{
      Toast.fire({
        icon: 'success',
        title: '<span style="color: #fff">'+acao+'</span>',
      });
    }

}