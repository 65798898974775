import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table, Button, Form, Label } from "reactstrap";
import { index } from "../../../config/pluginsInit";
import { useForm } from 'react-hook-form';
import { Modal } from "react-bootstrap";
import api from '../../../services/Api';
import InputMask from 'react-input-mask';

import { Pagination } from 'react-bootstrap';

import mixin from '../../../Utils/mixin';
import Swal from 'sweetalert2';
import Estados from '../../../Utils/Estados';
import validadorCPF from '../../../Utils/Cpf';

export default function Documentos() {

  const baseRoute = "/users/";

  const [cep, setCEP] = useState();
  const [cpf, setCPF] = useState();
  const arr = JSON.parse(localStorage.getItem('credentials'));
  const token = arr.token;


  const { register, handleSubmit } = useForm();

  const [id, setId] = useState([]);
  const [system, setSystem] = useState([]);
  const [systemOne, setSystemOne] = useState([]);

  const [pagination, setPagination] = useState([]);

  const [ModalOpen, setModalOpen] = React.useState(false); // ABRE E FECHA O MODAL
  const [ModalOpenEdit, setModalOpenEdit] = React.useState(false); // ABRE E FECHA O MODAL

  const [ModalToggle, setModalToggle] = React.useState('adicionar'); // ABRE E FECHA O MODAL
  const [ModalToggleEdit, setModalToggleEdit] = React.useState('editar'); // ABRE E FECHA O MODAL

  // lista os dados na tabela
  useEffect(() => {
    autoload()

    index(); // fecha o loading

  }, []);

  // adicionar 
  async function handleModalAdd(acao) { setModalOpen(acao) }  // modal

  async function autoload() {


    const response = await api.get(baseRoute + 'list', {}, {
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}`
      }
    });
    setSystem(response.data.data);
  }

  const onSubmit = async (data) => {


    const username = cpf.split(".").join("").replace("-", "");

    const responsecpf = await validadorCPF(username);

    if (responsecpf != true) {
      //Swal.fire("CPF", 'O CPF é inválido!', 'warning');
      //return
    }

    if (data.senha != data.senha2) {
      Swal.fire("Senha Diferente", 'As senhas digitadas, não conferem.', 'warning');
      return
    }

    const response = await api.post(baseRoute + 'register-backoffice', {
      username: username,
      cpf: cpf,
      nome: data.nome,
      sobrenome: data.sobrenome,
      email: data.email,
      senha: data.senha,
      senha_confirma: data.senha2,
      endereco: data.endereco,
      numero: data.numero,
      bairro: data.bairro,
      complemento: data.complemento,
      cidade: data.cidade,
      estado: data.estado,
      cep: cep,
      is_active: data.status,
      client_mil: data.cliente
    }, {
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}`
      }
    });

    console.log(response)

    if (response.data.success == true) {
      mixin('add');
      autoload();
      setModalOpen(false);
    } else {
      Swal.fire('Atenção', response.data.message, 'error');
      //mixin('error');
    }
  }

  // editar  
  const [client_mil, setClient_mil] = useState([]);
  async function handleModalEditar(id, acao) {


    const response = await api.get(baseRoute + 'list/' + id, {}, {
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}`
      }
    });


    console.log(response.data.data)
    setSystemOne(response.data.data)
    setCPF(response.data.data[0].cpf)
    setCEP(response.data.data[0].cep)
    setClient_mil(response.data.data[0].client_mil);

    setId(id);
    setModalOpenEdit(acao);

  }


  const onSubmitEdit = async (data) => {

    const response = await api.put(baseRoute + 'update/' + id, {
      nome: data.nome,
      sobrenome: data.sobrenome,
      email: data.email,
      endereco: data.endereco,
      numero: data.numero,
      bairro: data.bairro,
      complemento: data.complemento,
      cidade: data.cidade,
      estado: data.estado,
      cep: cep,
      is_active: data.status,
      client_mil: data.cliente
    }, {
      headers: {
        'Content-Type': "application/json",
        'Authorization': `Bearer ${token}`
      }
    });


    mixin('edit');
    autoload();
    setModalOpenEdit(false);


  }

  async function redefinirSenha() {
    const response = await api.post('/forgot-password/', {
      cpf: cpf
    });

    if (response.data.success == true) {
      Swal.fire("Sucesso", response.data.message, 'success');
    } else {
      Swal.fire('Oops', response.data.message, 'warning');
    }
  }

  return (
    <>
      <div>

        <Modal
          size="lg"
          show={ModalOpen}
          onHide={() => setModalOpen(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" className="needs-validation">
            <Modal.Header closeButton={false}>
              <Modal.Title id="example-modal-sizes-title-lg">
                Adicionar
                        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row form>


                <Col md={6} className="mb-3">
                  <Label for="name"> Nome </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="nome"
                    name="nome"
                    ref={register}
                    required
                  />
                </Col>
                <Col md={6} className="mb-3">
                  <Label for="name"> Sobrenome </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="sobrenome"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={4} className="mb-3">
                  <Label for="name"> CPF </Label>
                  <InputMask
                    mask="999.999.999-99"
                    type="text"
                    className="form-control"
                    onChange={(e) => setCPF(e.target.value)}
                    id="cpf"
                    required
                  />
                </Col>


                <Col md={8} className="mb-3">
                  <Label for="name"> E-Mail </Label>
                  <input
                    type="email"
                    className="form-control"
                    id="name"
                    name="email"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Label for="name"> Senha </Label>
                  <input
                    type="password"
                    className="form-control"
                    id="name"
                    minLength="6"
                    maxLength="10"
                    name="senha"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Label for="name"> Confirme a senha </Label>
                  <input
                    type="password"
                    className="form-control"
                    id="name"
                    minLength="6"
                    maxLength="10"
                    name="senha2"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={2} className="mb-3">
                  <Label for="name"> CEP </Label>
                  <InputMask
                    mask="99999-999"
                    type="text"
                    className="form-control"
                    onChange={(e) => setCEP(e.target.value)}
                    id="cpf"
                    required
                  />
                </Col>

                <Col md={8} className="mb-3">
                  <Label for="name"> Endereço </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="endereco"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={2} className="mb-3">
                  <Label for="name"> Número </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="numero"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={6} className="mb-3">
                  <Label for="name"> Bairro </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="bairro"
                    ref={register}
                    required
                  />
                </Col>



                <Col md={6} className="mb-3">
                  <Label for="name"> Complemento </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="complemento"
                    name="complemento"
                    ref={register}
                  />
                </Col>

                <Col md={5} className="mb-3">
                  <Label for="name"> Cidade </Label>
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    name="cidade"
                    ref={register}
                    required
                  />
                </Col>

                <Col md={2} className="mb-3">
                  <Label for="active"> Estados </Label>
                  <select name="estado" ref={register} className="form-control" id="active" required>
                    <option disable selected value="">Selecione...</option>
                    {Estados.map(uf => (
                      <option key={uf.uf} value={uf.uf}> {uf.uf} </option>
                    ))}

                  </select>
                </Col>

                <Col md={3} className="mb-3">
                  <Label for="active"> Cliente Mil Contas </Label>
                  <select name="cliente" ref={register} className="form-control" id="active" required>
                    <option disable selected value="">Selecione...</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                </Col>


                <Col md={2} className="mb-3">
                  <Label for="active"> Situação </Label>
                  <select name="status" ref={register} className="form-control" id="active" required>
                    <option disable selected value="">Selecione...</option>
                    <option value="1"> Ativo </option>
                    <option value="0"> Inativo </option>
                  </select>
                </Col>

              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button color="primary" type="submit">Enviar</Button>
              <Button color="secondary" onClick={() => handleModalAdd(false)} >Fechar</Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          size="lg"
          show={ModalOpenEdit}
          onHide={() => handleModalEditar('', false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Form onSubmit={handleSubmit(onSubmitEdit)} autoComplete="off" className="needs-validation">
            <Modal.Header closeButton={false}>
              <Modal.Title id="example-modal-sizes-title-lg">
                Editar
                        </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {systemOne.map(one => (
                <Row form>
                  <Col md={6} className="mb-3">
                    <Label for="name"> Nome </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.name}
                      id="nome"
                      name="nome"
                      ref={register}
                      required
                    />
                  </Col>
                  <Col md={6} className="mb-3">
                    <Label for="name"> Sobrenome </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.last_name}
                      id="name"
                      name="sobrenome"
                      ref={register}
                      required
                    />
                  </Col>

                  <Col md={4} className="mb-3">
                    <Label for="name"> CPF </Label>
                    <InputMask
                      mask="999.999.999-99"
                      defaultValue={one.cpf}
                      disabled
                      type="text"
                      className="form-control"
                      onChange={(e) => setCPF(e.target.value)}
                      id="cpf"
                      required
                    />
                  </Col>


                  <Col md={8} className="mb-3">
                    <Label for="name"> E-Mail </Label>
                    <input
                      type="email"
                      className="form-control"
                      defaultValue={one.email}
                      id="name"
                      name="email"
                      ref={register}
                      required
                    />
                  </Col>

                  <Col md={2} className="mb-3">
                    <Label for="name"> CEP </Label>
                    <InputMask
                      mask="99999-999"
                      defaultValue={one.cep}
                      type="text"
                      className="form-control"
                      onChange={(e) => setCEP(e.target.value)}
                      id="cpf"
                      required
                    />
                  </Col>

                  <Col md={8} className="mb-3">
                    <Label for="name"> Endereço </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.endereco}
                      id="name"
                      name="endereco"
                      ref={register}
                      required
                    />
                  </Col>

                  <Col md={2} className="mb-3">
                    <Label for="name"> Número </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.numero}
                      id="name"
                      name="numero"
                      ref={register}
                      required
                    />
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label for="name"> Bairro </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.bairro}
                      id="name"
                      name="bairro"
                      ref={register}
                      required
                    />
                  </Col>

                  <Col md={6} className="mb-3">
                    <Label for="name"> Complemento </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.complemento}
                      id="complemento"
                      name="complemento"
                      ref={register}
                    />
                  </Col>

                  <Col md={5} className="mb-3">
                    <Label for="name"> Cidade </Label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={one.cidade}
                      id="name"
                      name="cidade"
                      ref={register}
                      required
                    />
                  </Col>

                  <Col md={2} className="mb-3">
                    <Label for="active"> Estados </Label>
                    <select name="estado" ref={register} defaultValue={one.estado} className="form-control" id="active" required>
                      <option disable selected value="">Selecione...</option>
                      {Estados.map(uf => (
                        <option key={uf.uf} value={uf.uf}> {uf.uf} </option>
                      ))}

                    </select>
                  </Col>

                  <Col md={3} className="mb-3">
                    <Label for="active"> Cliente Mil Contas </Label>
                    <select name="cliente" ref={register} defaultValue={one.client_mil} className="form-control" id="active" required>
                      <option disable selected value="">Selecione...</option>
                      <option value="1">Sim</option>
                      <option value="0">Não</option>
                    </select>
                  </Col>


                  <Col md={2} className="mb-3">
                    <Label for="active"> Situação </Label>
                    <select name="status" ref={register} defaultValue={one.id_active} className="form-control" id="active" required>
                      <option disable selected value="">Selecione...</option>
                      <option value="1"> Ativo </option>
                      <option value="0"> Inativo </option>
                    </select>
                  </Col>

                </Row>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button color="info" onClick={redefinirSenha} type="button">Redefinir Senha</Button>
              <Button color="primary" type="submit">Enviar</Button>
              <Button color="secondary" onClick={() => handleModalEditar('', false)} >Fechar</Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>


      <Row>
        <Col sm="12">
          <Card className="iq-card">
            <CardHeader className="iq-card-header d-flex justify-content-between">
              <CardTitle className="iq-header-title">
                <h4 className="card-title"> Declarantes </h4>
              </CardTitle>
              {/* <span className="float-right" >
                                {/* <Button color="warning" className="btn-filter"  type="submit"><i className="ri-add-circle-line" /> Abrir Filtro</Button> */}
              {/* <Button color="primary" onClick={ () => handleModalAdd(true) } type="submit"><i className="ri-add-circle-line" /> Adicionar</Button> */}
              {/* </span> */}
            </CardHeader>
            <CardBody className="iq-card-body">
              <Table responsive striped className="table">
                <thead>
                  <th scope="col" className="text-center"> Pedido </th>
                  <th scope="col"> CPF  </th>
                  <th scope="col" className="text-center"> Declarante </th>
                  <th scope="col" className="text-center"> E-Mail </th>
                  <th scope="col" className="text-center"> Telefone </th>
                  <th scope="col" className="text-center"> Tipo </th>
                  <th scope="col" className="text-center"> Categoria </th>
                  <th scope="col" className="text-center"> Valor </th>
                  <th scope="col" className="text-center"> Atendente </th>
                  <th scope="col" className="text-center"> Data cadastro </th>
                  <th scope="col" className="text-center">  Situação </th>
                  <th scope="col" className="text-center">  Data situação </th>
                  <th scope="col" className="text-center">  Ação </th>
                </thead>
                <tbody>
                  {system.map(sys => (
                    <>
                      {(sys.client_mil_name === "Não") ?
                        <tr >
                          <td className="Tabela_id"> 0001 </td>
                          <td> 123.123.123-12 </td>
                          <td className="text-center"> Declarante tal </td>
                          <td className="text-center"> declarante@teste.com </td>
                          <td className="text-center"> (12) 12345-1234 </td>
                          <td className="text-center"> Simplificada </td>
                          <td className="text-center"> Empresario </td>
                          <td className="text-center"> R$ 150,00 </td>
                          <td className="text-center"> Atendente </td>
                          <td className="text-center"> 04/04/2020 </td>
                          <td className="text-center"> Situação </td>
                          <td className="text-center"> 10/04/2020 </td>
                          <td className="Tabela_editar">
                            <Button outline color="success" onClick={() => ('')} className="btn-fintoro">Ver respostas</Button>
                          </td>
                        </tr>
                        : ""}
                    </>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>

      </Row>
      {(pagination == 1) ? "" :
        <Pagination className={"justify-content-end"}>
          <Pagination.Item>{"Anterior"}</Pagination.Item>
          {
            [...Array(pagination)].map((x, i) =>
              <Pagination.Item onClick={() => ((i + 1))} key={i}>{(i + 1)}</Pagination.Item>
            )}
          <Pagination.Item >{"Próximo"}</Pagination.Item>
        </Pagination>
      }
    </>
  );

}