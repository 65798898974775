import api from '../../services/Api';

export const isAuthenticated = () => {

  const arr = JSON.parse(localStorage.getItem('credentials'));
  if(arr == null || arr == undefined){
    return false;
  }
  if(arr.token != null){
    return true;
  }
  return false;    
  
}