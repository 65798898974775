import React from 'react';
import {Link, NavLink} from "react-router-dom";
import {MenuListStyle1} from "../../index";
import MenuListStyle2 from '../ListStyle2/index';
import {getActiveLink} from "../../../../config/pluginsInit";

const index = (props) => { 
    const { items, display } = props;
    return (
        <ul className={"iq-submenu"} style={ display !== undefined && display === true ? style : {} }>
            { items.map((subItem, subIndex) => (
                <>
                {(subItem.link !== undefined) ? (
                    <li key={subIndex + "submenu"}>
                        <Link to={ subItem.link } className="iq-waves-effect">
                            { subItem.icon && (<i className={ subItem.icon } />) }
                            <span>{ subItem.title }</span>

                            { (subItem.children !== undefined && subItem.children.length > 0) && (<i className="ri-arrow-right-s-line iq-arrow-right" />) }
                            { (subItem.coming_soon !== undefined && subItem.coming_soon === true) && (
                                <small className="badge badge-pill badge-primary float-right font-weight-normal ml-auto">Soon</small>
                            ) }
                        </Link>
                    </li>
                ) : ( 
                    <a href={"!#"}  key={subIndex + "submenu"} className="iq-waves-effect">
                        { subItem.icon && (<i className={ subItem.icon } />) }
                        <span>{ subItem.title }</span>

                        { (subItem.children !== undefined && subItem.children.length > 0) && (<i className="ri-arrow-right-s-line iq-arrow-right" />) }
                    </a>
                )
                }

                {/* Code for the Sub Sub menu */}
                { (subItem.children !== undefined && subItem.children.length > 0) && (
                    <MenuListStyle2 items={subItem.children} display={getActiveLink(subItem)} />
                ) }
                </>
            )) }
        </ul>
    );
};
const style = {
    display: "block"
};

export default index;
