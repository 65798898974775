export const inboxEmailData = [
    {
        is_selected: false,
        is_unread: true,
        favorite: true,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@Mackenzieniko) has sent you a direct message on Twitter! &nbsp;–&nbsp;",
        emailTime: "08:00 am",
        profileImage: require("../../../../assets/images/user/user-1.jpg")
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    }
];



export const starredEmailsData = [
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    }
];



export const deletedEmailsData = [
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    }
];



export const spamEmailsData = [
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
];



export const draftEmailsData = [
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    }
];


export const sendEmailData = [
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings",
        subject: "Megan Allen (@meganallen) has sent you a direct message on Twitter!  ",
        emailTime: "08:00 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Lauren Drury (Me)",
        subject: "Dixa Horton (@dixahorton) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Fabian Ros (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: true,
        favorite: false,
        title: "Megan Allen (Me)",
        subject: "Eb Begg (@ebbegg) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Jopour Xiong (Me)",
        subject: "Mackenzie Niko (@mackenzieniko) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
    {
        is_selected: false,
        is_unread: false,
        favorite: false,
        title: "Deray Billings (Me)",
        subject: "Jecno Mac (@jecnomac) has sent you a direct message on Twitter!  ",
        emailTime: "11:49 am"
    },
];
